import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import DownArrow from "../../assets/svg/hanson-arrow.svg";
import { css, keyframes } from "@emotion/react";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";
import sr from "../../utils/sr";

const Root = styled.section``;

const Intro = styled.div`
  display: flex;
  margin: 6.25rem 1rem 7.5rem;
  max-width: 1600px;
  cursor: default;

  @media screen and (min-width: 479px) {
    margin-top: 7.5rem;
    margin-bottom: 8.75rem;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  @media screen and (min-width: 767px) {
    margin: 7.5rem 2.5rem 8.75rem 2.5rem;
  }

  @media screen and (min-width: 991px) {
    margin: 7.5rem auto 10rem;
    padding-right: 5rem;
    padding-left: 5rem;
  }
`;

const Heading = styled.h1`
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.02em;
  font-family: Lora;
  font-size: 2.25rem;
  letter-spacing: -0.02em;
  line-height: 150%;

  @media screen and (min-width: 991px) {
    font-size: 2.875rem;
  }
`;

const Selected = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 7.5rem 1rem 2.5rem;
  max-width: 1600px;

  @media screen and (min-width: 479px) {
    font-size: 1.25rem;
    margin-top: 7.5rem;
    margin-bottom: 3.75rem;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  @media screen and (min-width: 767px) {
    margin: 7.5rem 2.5rem 3.75rem 2.5rem;
  }

  @media screen and (min-width: 991px) {
    margin-top: 7.5rem;
    margin-right: auto;
    margin-bottom: 3.75rem;
    margin-left: auto;
    padding-right: 5rem;
    padding-left: 5rem;
  }
`;

const bounce = keyframes`
  from { opacity: 0; }
    to   { opacity: 1; }
`;

const Hero = () => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const revealHeader = useRef();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }
    sr.reveal(revealHeader.current, { distance: "0px" });
  }, []);

  return (
    <Root>
      <Intro>
        <Heading
          css={css`
            animation: ${bounce} 1s ease;
          `}
        >
          Web Developer looking for Opportunities.
          <br />
          Currently based in Vienna, Austria.
        </Heading>
      </Intro>

      <Selected id="selected" href="#selected">
        <DownArrow
          css={css`
            margin-right: 12px;
          `}
        />
        Selected Projects
      </Selected>
    </Root>
  );
};

export default Hero;
