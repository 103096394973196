import * as React from "react"
import SEO from "../components/Elements/SEO/SEO"
import Projects from "../components/Sections/Projects"
import Hero from "../components/Sections/Hero"
import { motion } from "framer-motion"

// add to html 
// <!--!
//   Oh so you're an inspector?
//   View the source here: https://github.com/maxboeck/mxb
// -->

const IndexPage = ({ location }) => {
  // const projects = data.allMdx.nodes
  return (
    <>

      <SEO
        title={'Home'}
      />
      <motion.main
        initial={{ opacity: 0, x: -200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -200 }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 0.3
        }}
      >

        <Hero />
        <Projects />
      </motion.main>
    </>
  )
}

export default IndexPage

